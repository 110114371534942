import React, { useEffect, useState, forwardRef } from 'react';

import '../UI/PrintReport.css';
import { useApi } from '../ContextApi/ContextApi';
import Template1 from '../Templates/Template1';
import Template2 from '../Templates/Template2';
import Template3 from '../Templates/Template3';
import Template4 from '../Templates/Template4';

const PrintReports = forwardRef(({reportData,DRInfos,Fonts,QrCodeURL}, ref) => {
  return (
    <>
      {reportData?.[0]?.selectedTemplate === '1' ? (
      <div ref={ref}>
       <Template1 reportData={reportData} FontStyle={Fonts} QRurl={QrCodeURL} />
     </div>
     ) : reportData?.[0]?.selectedTemplate === '2' ? (
    <div  ref={ref}>
      <Template2 reportData={reportData} DRS={DRInfos} FontStyle={Fonts} QRurl={QrCodeURL} />
    </div>
    ) :reportData?.[0]?.selectedTemplate === '3' ?  (
      <div ref={ref}>
      <Template3 reportData={reportData} QRurl={QrCodeURL}/>
     </div>
   ):<div ref={ref}>
     <Template4 reportData={reportData} DRS={DRInfos} FontStyle={Fonts} QRurl={QrCodeURL}/>
    </div>
    }
    </>
  );
});

export default PrintReports;
