import axios  from "axios";
import {jwtDecode} from 'jwt-decode';
// const baseURL = 'https://localhost:44397/api/'; //local
const baseURL = 'https://securelab.api.sehhasolutions.com/api/'; //prod


const apiServices = axios.create({
    baseURL,
})
//-------------------------Check Token Expiration----------------
const isTokenExpired = () => {
    const token = sessionStorage.getItem('token');
    if (!token) return true; 
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now();
    const expirationTime = decodedToken.exp * 1000;
    return currentTime >= expirationTime; 
  };
  
  const logout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    localStorage.clear(); 
    window.location.href = '/';
  };
  apiServices.interceptors.request.use(
    (config) => {
      if (isTokenExpired()) {
        logout(); 
        return Promise.reject('Token expired'); 
      }
  
    //   const token = sessionStorage.getItem('token');
    //   if (token) {
    //     config.headers['Authorization'] = `Bearer ${token}`; 
    //   }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
//-------------------------END------------------------
export  const PatientRegistration_LabOrders = async (data,Order) =>{
    try{
        const token = sessionStorage.getItem('token');
        const response  = await apiServices.post('Home/PatientRegistration',
          {
              patient: {
                    PatientID:data.PatientID ? data.PatientID :null,
                    PatientName:data.PatientName,
                    ReferredBy: data.ReferredBy,
                    ContactNumber:data.ContactNumber,
                    Sex:data.gender,
                    Age:data.Age,
                    Comments :data.Comments,
                    // DOB:data.DOB,
                    Address:data.Address,
                    LaboratoryID:data.LaboratoryID
                },
                laborder: Order.map(order => ({
                    TestID: order.TestID,
                    GrandTotal: order.GrandTotal,
                    TotalBill: order.TotalBill,
                    Discount: order.Discount,
                    CreatedBy: sessionStorage.getItem('UserID'),
                    UpdatedBy: sessionStorage.getItem('UserID'),
                    LaboratoryID: sessionStorage.getItem('LabID'),
                })),
                financialRecord: {
                    LaboratoryID: sessionStorage.getItem('LabID'),
                    createdBy: sessionStorage.getItem('UserID'),
                    updatedBy: sessionStorage.getItem('UserID'),
                    TotalBill: Order[0].TotalBill,
                    Discount: Order[0].Discount,
                    GrandTotal: Order[0].GrandTotal,
                    ReceivedAmount: Order[0].ReceivedAmount,
                    DueAmount: Order[0].DueAmount
                  }
            } ,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                }
            });
        
        return response;
    }
    catch(error){
        throw error
       }
}
export  const RetrivePatientsData = async (LabID) =>{
        try{
            const token = sessionStorage.getItem('token');
            const response  = await apiServices.get(`/Home/GetPatient?LabID=${LabID}`,{
                headers: {
                    'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                }
            });
            return response.data;
        }
        catch(error){
            throw error
           }
    }
    export  const GetSinglepatient = async (id,LabID) =>{
        
        try{
            const token = sessionStorage.getItem('token');
            const response  = await apiServices.get(`/Home/GetSinglePatient?id=${id}&LabID=${LabID}`,{
                headers: {
                    'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                }
            });
            return response.data;
        }
        catch(error){
            throw error
           }
    }

    export  const GloabalSearchPatient = async (id,name) =>{
        
        try{
            const token = sessionStorage.getItem('token');
            const response  = await apiServices.get(`/Home/PatientGloablSearch?labid=${id}&Name=${name}`,{
                headers: {
                    'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                }
            });
            return response.data;
        }
        catch(error){
            throw error
           }
    }
    export  const DeletePatient = async (LabiD,PatientID) =>{
        
        try{
            const token = sessionStorage.getItem('token');
            const response  = await apiServices.delete(`/Home/DeletePatient?LabiD=${LabiD}&PatientID=${PatientID}`
            ,{
                headers: {
                    'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                }
            });
            return response.data;
        }
        catch(error){
            throw error
           }
    }