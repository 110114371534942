import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination,
    Paper,
    TablePagination,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
  } from '@mui/material';
  import { styled } from '@mui/material/styles';
  import  { tableCellClasses } from '@mui/material/TableCell';
import { useEffect,useState} from 'react';
import { toast } from 'react-toastify';
import { MonthlyDailyReport } from '../../Services/Dashboard';
import moment from 'moment/moment';
import SpinnerModal from '../../Spinner/SpinnerModal';
import Stack from '@mui/material/Stack';
import './Reports.css'

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2c6bd7',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: '#ffffff',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nStyledTableCell-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child StyledTableCell, &:last-child StyledTableCell': {
      border: 0,
    },
  }));






const DailyReports = () =>{
    const [isLoading, setIsLoading] = useState(false);
    const[DailyReports,DailyReport] = useState([]);




  //     --------- Monthly daily report----------
const GetMonthlyDaily = async () =>{
    try{
      setIsLoading(true);
      const LabID = sessionStorage.getItem('LabID');
      const response = await MonthlyDailyReport(LabID);
      DailyReport(response.dailyReports);
        
    }catch(error){
      toast.error("Unabled to get States data",error)
    }finally{
      setIsLoading(false);
    }
  }

  useEffect(()=>{
    GetMonthlyDaily();
  },[])
       //-------------------Pagination

const itemsPerPage = 10; // Number of items per page
const [currentPage, setCurrentPage] = useState(1); // Pages start from 1

// Calculate the range of items to display on the current page
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const limitedData = DailyReports.slice(indexOfFirstItem, indexOfLastItem);
// Handle page change
const handlePageChange = (event, newPage) => {
  setCurrentPage(newPage);
};
//---------------------END---------

    return(
        <>
         {isLoading && <SpinnerModal isLoading={isLoading} />}
         
             <div>
               <div className='heading-div'>
               </div>
               <div>
               <TableContainer className='PatientListTable' sx={{ maxHeight: '80vh',minHeight:'80vh' }}>
                <Table  stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <StyledTableRow>
                              <StyledTableCell className='DTC1'>Date</StyledTableCell>
                                <StyledTableCell className='DTC1'>Day</StyledTableCell>
                                <StyledTableCell className='DTC1'>Tests</StyledTableCell>
                                <StyledTableCell className='DTC1'>Sale</StyledTableCell>
                                <StyledTableCell className='DTC1'>Discount</StyledTableCell>
                                <StyledTableCell className='DTC1'>Received Amount</StyledTableCell>
                                <StyledTableCell className='DTC1'>Due Amount</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>  
                    {limitedData.map((data, index) => (
                         <StyledTableRow  key={index}>
                                <StyledTableCell className='DTC common-FontWeight'>{moment(data.recordDate).format('D MMM YYYY')} </StyledTableCell>
                                <StyledTableCell className='DTC common-FontWeight'>{data.dayName} </StyledTableCell>
                                <StyledTableCell className='DTC common-FontWeight'>{data.totalTest}</StyledTableCell>
                                <StyledTableCell className='DTC common-FontWeight'>{data.totalGrandTotal}</StyledTableCell>
                                <StyledTableCell className='DTC common-FontWeight'>{data.totalDiscount}</StyledTableCell>
                                <StyledTableCell className='DTC common-FontWeight'>{data.receivedAmount}</StyledTableCell>
                                <StyledTableCell className='DTC common-FontWeight'>{data.dueAmount}</StyledTableCell>
                            </StyledTableRow>
                                ))}
                    </TableBody>
                  
                </Table>
                <hr></hr>

                
                <Stack>
                  <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={Math.ceil(DailyReports.length / itemsPerPage)} // Total pages
                      page={currentPage}
                      onChange={handlePageChange} // Correct prop for handling page changes
                    />
                  </Stack>
                  </TableContainer>
             
               </div>
             </div>
             
        </>
    )
}

export default  DailyReports