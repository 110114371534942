import React from 'react';
import { Table, TableBody, td, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import QRCode from 'react-qr-code';  
import '../UI/PrintReport.css';

const Template4 = ({ reportData, DRS, FontStyle,QRurl }) => {
  const currentDateTime = new Date();
  const formattedCurrentDate = moment(currentDateTime).format('D MMM YYYY');
  const username = sessionStorage.getItem("FirstName") + sessionStorage.getItem("LastName");

  const groupByCategoryAndSubCategory = (data) => {
    return data.reduce((result, item) => {
      const category = item.categorY_CODE;
      const subCategory = item.suB_CATEGORY_CODE;

      if (!result[category]) {
        result[category] = {
          categoryValue: item.categorY_VALUE,
          subCategories: {},
        };
      }

      if (!result[category].subCategories[subCategory]) {
        result[category].subCategories[subCategory] = {
          subCategoryValue: item.suB_CATEGORY_VALUE,
          items: [],
        };
      }

      result[category].subCategories[subCategory].items.push(item);
      return result;
    }, {});
  };
  const groupedData = groupByCategoryAndSubCategory(reportData);

  // Function to format normal range and unit values
  const formatTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const LabName = reportData.find((x) => x.laboratoryName != '' && x.laboratoryName != null)?.laboratoryName;

  //-----------result color ----------
  const Resultcalculation = (range, result) => {
    const [min, max] = range.split('-').map(Number);
    let color = 'black';
    if (result < min || result > max) {
        color = result < min ? 'blue' : 'red';  
    } else {
        color = 'black';  
    }
    return color;
};

const Getlowhighvalue = (range, result) =>{
    const [min, max] = range.split('-').map(Number);
    let Text = '';
    if (result < min || result > max) {
        Text = result < min ? 'Low' : 'High';  
    } else {
        Text = '';  
    }
    return Text;
}
//------------------END--------------
const qrCodeValue = QRurl ;
  return (
    <div className="print-report">
      <div className="page">
        {/* Header Section (Repeats on each page) */}
        <div className="print-header" style={{ fontFamily: FontStyle[0]?.fontfamily || 'Georgia serif' }}>
          <div>
            <img
              className="logo-img"
              src={`data:image/png;base64,${reportData?.[0]?.data}`}
              alt="Lab Logo"
              style={{ width: '100%', height: '130px', objectFit: 'contain' }}
            />
          </div>
          <div className='row ps-2'>
            <div className="patient-details col-sm-4" style={{fontSize: FontStyle[0]?.fontsize || '10px' }}>
                <div>
                <span style={{fontSize:'20px',fontWeight:'700'}}>{reportData?.[0]?.patientName}</span>
                </div>   
                <div>
                <span style={{ fontWeight: 'bold' }}>MR No:</span>&nbsp;
                <span>{reportData?.[0]?.patientID}-{sessionStorage.getItem('LabID')}</span>
                </div>
                {/* <div>
                <span style={{ fontWeight: 'bold' }}>Patient Name:</span>&nbsp;
                <span>{reportData?.[0]?.patientName}</span>
                </div> */}
                <div>
                <span style={{ fontWeight: 'bold' }}>
                    {reportData?.[0]?.sex === 'male' ? 'Father Name :' : 'Father / Husband :'}</span>
                </div>
                <div>
                <span style={{ fontWeight: 'bold' }}>Age:</span>&nbsp;
                <span>{reportData?.[0]?.age} Years</span>
                </div>
                <div>
                <span style={{ fontWeight: 'bold' }}>Gender:</span>&nbsp;
                <span>{reportData?.[0]?.sex}</span>
                </div>
                
            </div>
            <div className='col-sm-4 align-items-center'>
                <div>
                   <QRCode value={qrCodeValue} size={80} />
                </div>
            </div>
            <div className='col-sm-4'>
                <div>
                    <span style={{ fontWeight: 'bold' }}>Sample Collected At:</span>&nbsp;
                    <span>{reportData?.[0]?.address}</span>
                </div>
                <div style={{ textAlign: 'start' }}>
                  <span style={{ fontWeight: 'bold' }}>Phone Number:</span>&nbsp;<span>{reportData?.[0]?.contactNumber}</span>
                </div>
                <div style={{ textAlign: 'start' }}>
                  <span style={{ fontWeight: 'bold' }}>Referred by:</span>&nbsp;<span>{reportData?.[0]?.referredBy}</span>
                </div>
                <div style={{ textAlign: 'start' }}>
                  <span style={{ fontWeight: 'bold' }}>Register on:</span>&nbsp;<span>{moment(reportData?.[0]?.createdAt).format('D MMM YYYY')}</span>
                </div>
            </div>
          </div>
          
          
        </div>

        {/* Table Section */}
        <div style={{padding:'10px'}}>
          {Object.keys(groupedData).map((category, index) => (
            <div className="category-section" key={index}>
              <h3 style={{ textAlign: 'center',fontWeight:'700' }}>
                {groupedData[category].categoryValue}
              </h3>
              {Object.keys(groupedData[category].subCategories).map((subCategory, subIndex) => (
                <div key={subIndex}>
                  <span style={{ fontWeight: 'bold' ,fontSize:'15px'}}>
                    {groupedData[category].subCategories[subCategory].subCategoryValue}
                  </span>
                  <TableContainer>
                    <table style={{ width: '100%', tableLayout: 'fixed' }}>
                      <thead style={{borderTop:'1px solid black',borderBottom:'1px solid black'}}>
                        <tr>
                          <td style={{ width: '40%' ,fontWeight:'700'}}>Investigation</td>
                          <td style={{ width: '20%',fontWeight:'700' }}>Unit</td>
                          <td style={{ width: '20%',fontWeight:'700' }}>Reference Value</td>
                          <td style={{ width: '20%',fontWeight:'700' }}>Result</td>
                        </tr>
                      </thead>
                      <tbody>
                        {groupedData[category].subCategories[subCategory].items.map((data, index) => (
                          <tr key={index}>
                            <td>{data.testName}</td>
                            <td>{formatTextWithLineBreaks(data.unit)}</td>
                            <td>{formatTextWithLineBreaks(data.normalRange)}</td>
                            <td style={{ fontWeight: 'bold',color:Resultcalculation(data.normalRange,data.testResult)}}>{data.testResult} {Getlowhighvalue(data.normalRange,data.testResult)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TableContainer>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* Footer Section (Repeats on each page) */}
        <div className="print-footer" style={{ fontFamily: FontStyle[0]?.fontfamily || 'Georgia serif' }}>
          <hr />
          <div className="footer-content">
            <span>Printed at: {formattedCurrentDate}</span> &nbsp;
            <span>Printed By: {username}</span>
          </div>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                {DRS.map((data, index) => (
                  <td key={index} style={{ padding: '10px', verticalAlign: 'top' }}>
                    <div style={{ fontWeight: 'bold' }}>{data.drname}</div>
                    <div>{data.designation}</div>
                    <div>{data.description}</div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <div style={{ textAlign: 'center' }}>
            <p>&copy; {new Date().getFullYear()} {LabName}. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template4;
