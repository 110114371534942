import axios  from "axios";
import qs from 'qs';
import {jwtDecode} from 'jwt-decode';
// const baseURL = 'https://localhost:44397/api'; //local
const baseURL = 'https://securelab.api.sehhasolutions.com/api/'; //prod


const apiServices = axios.create({
    baseURL,
    paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    }
})
//-------------------------Check Token Expiration----------------
const isTokenExpired = () => {
    const token = sessionStorage.getItem('token');
    if (!token) return true; 
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now();
    const expirationTime = decodedToken.exp * 1000;
    return currentTime >= expirationTime; 
  };
  
  const logout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    localStorage.clear(); 
    window.location.href = '/';
  };
  apiServices.interceptors.request.use(
    (config) => {
      if (isTokenExpired()) {
        logout(); 
        return Promise.reject('Token expired'); 
      }
  
    //   const token = sessionStorage.getItem('token');
    //   if (token) {
    //     config.headers['Authorization'] = `Bearer ${token}`; 
    //   }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
//-------------------------END------------------------

export  const AddTest = async (data) =>{
    try{
        const token = sessionStorage.getItem('token');
        const response  = await apiServices.post('/Home/AddTest',
          {
            CATEGORY_CODE:data.CATEGORY_CODE != ''? data.CATEGORY_CODE:'0',
            GenderCategory:data.GenderCategory != ''? data.GenderCategory:'0' ,
            AGE_CODE:data.AGE_CODE != ''? data.AGE_CODE:'0',
            TestID:data.TestID ? data.TestID :0,
            TestName: data.test_name, 
            TestPrice: data.TestPrice,
            Unit : data.Unit,
            NormalRange: data.Reference_value,
            Comments:data.Comments,
            LaboratoryID:sessionStorage.getItem('LabID'),
            SUB_CATEGORY_CODE:data.SUB_CATEGORY_CODE != '' ? data.SUB_CATEGORY_CODE:'0',
          } 
          ,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response;
    }
    catch(error){
        console.log("Add Test error",error)
        throw error
       }
}
export  const RetriveTest = async (Params) =>{
        try{
            const token = sessionStorage.getItem('token');
            const CATEGORY_CODE = Params.CATEGORY_CODE !=""? Params.CATEGORY_CODE:'0';
            const GenderCategory = Params.GenderCategory != ""? Params.GenderCategory:'0';
            const AGE_CODE = Params.AGE_CODE != ""? Params.AGE_CODE:'0';
            const SUB_CATEGORY_CODE = Params.SUB_CATEGORY_CODE != ""? Params.SUB_CATEGORY_CODE:'0';
            const response  = await apiServices.get(`/Home/GetTests?LabID=${Params.LabID}&CATEGORY_CODE=${CATEGORY_CODE}&GenderCatagory=${GenderCategory}&AGE_CODE=${AGE_CODE}&SUB_CATEGORY_CODE=${SUB_CATEGORY_CODE}`,{
                headers: {
                    'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                }
            });
            return response.data;
        }
        catch(error){
            
            console.log("Add Test error",error)
            throw error
           }
    }

    export  const GetDataBySearch = async (Params) =>{
            try{
                const token = sessionStorage.getItem('token');
                 const GenderCategory = Params.GenderCategory != ""? Params.GenderCategory : '0';
                 const AgeCode = Params.AGE_CODE != ""? Params.AGE_CODE : '0';

                const response  = await apiServices.get(`/Home/GetTestforSearch?Queryparams=${Params.SearchParam}&LabID=${Params.LabID}&GenderCatagory=${GenderCategory}&AGE_CODE=${AgeCode}`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                    }
                });
                console.log("response",response)
                return response.data;
            }
            catch(error){
                console.log("Add Test error",error)
                throw error
               }
        }

        // export  const AddPrescription = async (data) =>{
        //     try{
        //         const response  = await apiServices.post('/Home/GenerateLabOrder',data )
        //         return response;
        //     }
        //     catch(error){
        //         console.log("Add Test error",error)
        //         throw error
        //     }
        // }

        export  const GetPrescription = async (patientID,LabID) =>{
            try{
                const token = sessionStorage.getItem('token');
                const response  = await apiServices.get(`/Home/GetLabOrderDatas?PatientID=${patientID}&LabID=${LabID}`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,  
                    }
                });
                return response.data;
            }
            catch(error){
                console.log("Add Test error",error)
                throw error
               }
        }

        export  const Reports = async (patientID,LabID,SelectedIDs) =>{
            try{
                const token = sessionStorage.getItem('token');
                const response = await apiServices.get(`/Home/Reports`, 
                    {
                        params: {
                            PatientID: patientID,
                            LabID: LabID,
                            SelectedIDs: SelectedIDs.map(x =>x.labOrderID) // Join array elements with commas if required by your API
                        },
                        headers: {
                            'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                        }

                      }
                      );
                return response.data;
            }
            catch(error){
                throw error
               }
        }
        export  const GetSingleTest = async (TestID,LabID) =>{
            try{
                const token = sessionStorage.getItem('token');
                const response  = await apiServices.get(`/Home/GetSinglTetest?LabID=${LabID}&TestID=${TestID}`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                    }
                });
                return response.data;
            }
            catch(error){
                throw error
            }
        }
        export  const DeleteTest = async (TestID,LabID) =>{
            try{
                const token = sessionStorage.getItem('token');
                const response  = await apiServices.post(`/Home/DeleteTest?LabID=${LabID}&TestID=${TestID}`,{},{
                    headers: {
                        'Authorization': `Bearer ${token}`, 
                    }
                });
                return response.data;
            }
            catch(error){
                throw error
            }
        }

        export  const Generate_Reports = async (G_Report) =>{
            try{
                const token = sessionStorage.getItem('token');
                const response  = await apiServices.post('/Home/Generate_Report',
                {
                    "g_Report": [
                        {
                          "reportID":G_Report.reportID,
                          "patientID": G_Report.patientID,
                          "testResult": G_Report.testResult,
                          "testID": G_Report.testID,
                          "laboratoryID":( G_Report.laboratoryID != null && G_Report.laboratoryID != "") ? G_Report.laboratoryID :sessionStorage.getItem('LabID'),
                          "comment":G_Report?.Comment,
                          "labOrderID_In_GR":G_Report.labOrderID_In_GR
                        }
                      ]
                }
                ,{
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                    }
                });
                return response.data;
            }
            catch(error){
                throw error
            }
        }

        export const Add_TestSettings = async (formData) => {
            try {
                const token = sessionStorage.getItem('token');
                const payload = {
                    laboratoryid: formData.LabID,
                    categorY_VALUE: formData.CataGory_Value ? formData.CataGory_Value : "Null",
                    suB_CATEGORY_VALUE: formData.sub_Categorry ? formData.sub_Categorry : "Null",
                    agE_VALUE: formData.Age_Value ? formData.Age_Value : "Null",
                    sResult:formData.StaticResult ? formData.StaticResult : "Null",
                };
                console.log('Payload:', payload);  // Add this line for debugging
        
                const response = await apiServices.post('/Home/Add_TestSettings', payload,{
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                    }
                });
                return response.data;
            } catch (error) {
                throw error;
            }
        };
        
        
        export  const Get_Categorry = async (Labid) =>{
            try{
                const token = sessionStorage.getItem('token');
                const response  = await apiServices.get(`Home/Get_Categorry?LabID=${Labid}`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                    }
                });
                return response.data;
            }
            catch(error){
                throw error
            }
        }
     
        export  const Get_AgeGroup = async (Labid) =>{
            try{
                const token = sessionStorage.getItem('token');
                const response  = await apiServices.get(`Home/Get_AgeGroup?LabID=${Labid}`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                    }
                });
                return response.data;
            }
            catch(error){
                throw error
            }
        }

        export  const Get_SubCategory = async (Labid) =>{
            try{
                const token = sessionStorage.getItem('token');
                const response  = await apiServices.get(`Home/GetSubTestCategory?LabID=${Labid}`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                    }
                });
                return response.data;
            }
            catch(error){
                throw error
            }
        }

        export  const GetDoctorInfo = async (Labid) =>{
            try{
                const token = sessionStorage.getItem('token');
                const response  = await apiServices.get(`Home/GetDoctorInfo?LabID=${Labid}`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                    }
                });
                return response.data;
            }
            catch(error){
                throw error
            }
        }
        export  const GetFontStyles = async (Labid) =>{
            try{
                const token = sessionStorage.getItem('token');
                const response  = await apiServices.get(`Home/GetTemplateSettings?LabID=${Labid}`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                    }
                });
                return response.data;
            }
            catch(error){
                throw error
            }
        }
        export  const GetStaticResult = async (Labid) =>{
            try{
                const token = sessionStorage.getItem('token');
                const response  = await apiServices.get(`Home/GetStaticResult?LabID=${Labid}`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                    }
                });
                return response.data;
            }
            catch(error){
                throw error
            }
        }




    