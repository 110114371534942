import axios  from "axios";
import {jwtDecode} from 'jwt-decode';
// const baseURL = 'https://localhost:44397/api/'; //local
const baseURL = 'https://securelab.api.sehhasolutions.com/api/'; //prod


const apiServices = axios.create({
    baseURL,
})

//-------------------------Check Token Expiration----------------
const isTokenExpired = () => {
    const token = sessionStorage.getItem('token');
    if (!token) return true; 
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now();
    const expirationTime = decodedToken.exp * 1000;
    return currentTime >= expirationTime; 
  };
  
  const logout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    localStorage.clear(); 
    window.location.href = '/';
  };
  apiServices.interceptors.request.use(
    (config) => {
      if (isTokenExpired()) {
        logout(); 
        return Promise.reject('Token expired'); 
      }
  
    //   const token = sessionStorage.getItem('token');
    //   if (token) {
    //     config.headers['Authorization'] = `Bearer ${token}`; 
    //   }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
//-------------------------END------------------------

 
export  const GetDashboard =async (data) =>{
    const token = sessionStorage.getItem('token');
    try{
        const response =  await apiServices.get(`Home/Dashboard?Labid=${data}`,{
                headers: {
                    'Authorization': `Bearer ${token}`,  
                }
            });
        return response.data;
    }
    catch(error){
        throw(error);
    }
} 
export  const Monthly_Lab_Data =async (data) =>{
    const token = sessionStorage.getItem('token');
   
    try{
        const response =  await apiServices.get(`Home/Monthly_Lab_Data?Labid=${data.LabID}&StartDate=${data.StartDate}&EndDate=${data.EndDate}`,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data;
    }
    catch(error){
        throw(error);
    }
}
export  const MonthlyDailyReport =async (LabID) =>{
    const token = sessionStorage.getItem('token');
    try{
        const response =  await apiServices.get(`Home/GetDashboardMOnthlyDaily?LabiD=${LabID}`,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data;
    }
    catch(error){
        throw(error);
    }
} 

export  const getpendingtest =async (LabID) =>{
    const token = sessionStorage.getItem('token');
    try{
        const response =  await apiServices.get(`Home/GetPendingTest?LabiD=${LabID}`,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data;
    }
    catch(error){
        throw(error);
    }
} 