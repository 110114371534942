import react, {useEffect, useState}from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Pagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
  } from '@mui/material';
  import { styled } from '@mui/material/styles';
  import  { tableCellClasses } from '@mui/material/TableCell';
import SpinnerModal from '../../Spinner/SpinnerModal';
import { getpendingtest } from '../../Services/Dashboard';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import Stack from '@mui/material/Stack';
import './PendingTests.css'
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2c6bd7',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: '#ffffff',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const PendingTest = () =>{
    const [isLoading, setIsLoading] = useState(false);
    const [formdata, setformdata] = useState();
    const navigate = useNavigate();
    const getpendingtests = async ()=>{
      try{
        debugger
        setIsLoading(true)
         const LabID = sessionStorage.getItem('LabID')
         const Response = await getpendingtest(LabID);
         setformdata(Response)
        }
        catch(eror){
            console.log(eror)
        }finally{
            setIsLoading(false)
        }
    }
   
    useEffect(() => {
      getpendingtests();
  },[])
  const NavigatetoProfile = (id) =>{
    navigate(`/Home/PatientProfile/${id}`)
}
      //-------------------Pagination

      const itemsPerPage = 10; // Number of items per page
      const [currentPage, setCurrentPage] = useState(1); // Pages start from 1
      
      // Calculate the range of items to display on the current page
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const limitedData = formdata?.slice(indexOfFirstItem, indexOfLastItem);
      // Handle page change
      const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
      };
      //---------------------END---------
//================Search patient =============
const handleChange = (e) => {
  debugger
  const value = e.target.value.trim();  
  if(value == null || value == ""){
    getpendingtests();
  }else{
    const filtered = formdata.filter((patient) => {
      const patientName = patient?.patientName?.toLowerCase()?.trim();
      const testName = patient?.testName?.toLowerCase()?.trim();
      const searchValue = value.toLowerCase();
      return  patientName?.includes(searchValue) || testName?.includes(searchValue); 
    });
    setformdata(filtered);
  }
  }


//===================END==================
    return (
        <>
    {isLoading && <SpinnerModal isLoading={true} />}
    <div className='container-fluid Container2'>
    <div className='pl-child-div common-box-shadow'>
            <TableContainer className='PatientListTable'>
                <Table  stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <StyledTableRow >
                            <StyledTableCell className='PTC1'>Pending Test</StyledTableCell>
                            <StyledTableCell className='PTC1'>Patient Name</StyledTableCell>
                            <StyledTableCell className='PTC1 PT-searchbox'> Date
                            <input className="form-control common-Input PT-search-input" type="search" placeholder="Search" onChange={handleChange} aria-label="Search"></input>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                   
                    {formdata?.length > 0 ?
                    <TableBody>  
                           {limitedData?.map((data, index) => (
                            <StyledTableRow   key={index}>
                               <StyledTableCell onClick={() =>NavigatetoProfile(data.patientID)} className='PTC common-FontWeight common-FontWeight common-Link'>{data.testName}</StyledTableCell>
                               <StyledTableCell onClick={() =>NavigatetoProfile(data.patientID)} className='PTC common-FontWeight common-FontWeight common-Link'>{data.patientName}</StyledTableCell>
                               <StyledTableCell className='PTC common-FontWeight common-FontWeight'>{moment(data.createdAt).format('DD/MM/YYYY')}</StyledTableCell>
                            </StyledTableRow>
                            ))}
                    </TableBody>
                    :
                    <TableBody className='row' style={{textAlign:'center'}}>
                       <p style={{color:'gray'}}> No Record Found</p>
                    </TableBody>}
                    
                    
                </Table>
                <hr></hr>
                <Stack>
                  <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={Math.ceil(formdata?.length / itemsPerPage)} // Total pages
                      page={currentPage}
                      onChange={handlePageChange} // Correct prop for handling page changes
                    />
                  </Stack>
                
            </TableContainer>
            </div>
        </div>
        </>
        
    )
}
export default PendingTest