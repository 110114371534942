import axios  from "axios";
import {jwtDecode} from 'jwt-decode';
// const baseURL = 'https://localhost:44397/api'; //local
const baseURL = 'https://securelab.api.sehhasolutions.com/api/'; //prod


const apiServices = axios.create({
    baseURL,
})
//-------------------------Check Token Expiration----------------
const isTokenExpired = () => {
    const token = sessionStorage.getItem('token');
    if (!token) return true; 
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now();
    const expirationTime = decodedToken.exp * 1000;
    return currentTime >= expirationTime; 
  };
  
  const logout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    localStorage.clear(); 
    window.location.href = '/';
  };
  apiServices.interceptors.request.use(
    (config) => {
      if (isTokenExpired()) {
        logout(); 
        return Promise.reject('Token expired'); 
      }
  
    //   const token = sessionStorage.getItem('token');
    //   if (token) {
    //     config.headers['Authorization'] = `Bearer ${token}`; 
    //   }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
//-------------------------END------------------------

export  const AddLogoImage = async (formdata) =>{
    try{

        const token = sessionStorage.getItem('token');
        const response = await apiServices.post('/Home/UploadLogoImage', formdata,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data;
    }
    catch(error){
        console.log("Add Test error",error)
        throw error
       }
}
export  const GetLogoImage = async (LabID) =>{
    try{
        const token = sessionStorage.getItem('token');
        const response = await apiServices.get(`/Home/GetLogoImage?LabID=${LabID}`,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data;
    }
    catch(error){
        console.log("Add Test error",error)
        throw error
       }
}

export  const AddDrInfo = async (DrData) =>{
    try{
        const token = sessionStorage.getItem('token');
        const response = await apiServices.post('/Home/AddDoctorsInfo', 
            {
                "drname": DrData.drname,
                "designation": DrData.designation,
                "description": DrData.description,
                "address": DrData.address,
                "laboratoryid": DrData.laboratoryid  != null? DrData.laboratoryid:sessionStorage.getItem('LabID')
              }
              ,{
                headers: {
                    'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                }
            });
        return response.data;
    }
    catch(error){
        console.log("Add Test error",error)
        throw error
       }
}

export  const ADDTEMPLATESETTING = async (TEMPSET) =>{
    try{
        const token = sessionStorage.getItem('token');
        const response = await apiServices.post('/Home/AddTemplateSetting', 
            {
                "fontfamily": TEMPSET.fontfamily,
                "fontsize": TEMPSET.fontsize,
                "fontweight":'',
                "labid": TEMPSET.LabID  != null? TEMPSET.LabID:sessionStorage.getItem('LabID')
              }
              ,{
                headers: {
                    'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                }
            });
        return response.data;
    }
    catch(error){
        console.log("Add font error",error)
        throw error
       }
}

export  const GetDocotrInfo = async (LabID) =>{
    try{
        const token = sessionStorage.getItem('token');
        const response = await apiServices.get(`/Home/GetDoctorInfo?LabID=${LabID}`,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data;
    }
    catch(error){
        console.log("Get DR INfo error",error)
        throw error
       }
}

export  const DeleteDrInfo = async (LabiD,DRID) =>{
        
    try{
        const token = sessionStorage.getItem('token');
        const response  = await apiServices.delete(`/Home/DeleteDoctorInfo?LabiD=${LabiD}&DRID=${DRID}`,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data
    }
  
    catch(error){
        throw error
       }
}