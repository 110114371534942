import axios from 'axios';
// const baseURL = "https://localhost:44397/api/"; //Local
const baseURL = "https://securelab.api.sehhasolutions.com/api/"; //Prod


const apiService = axios.create({
  baseURL,
});

export const AddUsers = async(Data) =>{
    try{
        const response = await apiService.post('Auth/register',
        {
        FirstName: Data.FirstName,
        LastName: Data.LastName,
        Email:Data.Email,
        PhoneNumber:Data.PhoneNumber,
        Password:Data.Password,
        user_Role: Data.user_Role,
        LaboratoryID:Data.LaboratoryID
        }
        )
        return response;
    }
   catch(error){
    console.log("Add user error",error)
    throw error
   }
   
}
export const GetLogin = async(Data) =>{
  try{
      const response = await apiService.post('Auth/Login',
      {
          Email:Data.Email,
          Password:Data.Password
      } )
      return response.data;
  }
 catch(error){
  console.log("Add user error",error)
  throw error
 }
}
export const LabandUserRegistration = async (Data) =>{
    try{
        const res = await apiService.post('Auth/Registration',{
            UserModel: {
            FirstName: Data.FirstName,
            LastName: Data.LastName, 
            Email:Data.Email,
            PhoneNumber:Data.PhoneNumber,
            Password:Data.Password,
            user_Role:Data.user_Role,
          },
          LabDetails: {
            LaboratoryName:Data.LaboratoryName,
            LaboratoryContactNumber:Data.LaboratoryContactNumber,
            LaboratoryEmail:Data.LaboratoryEmail,
            LaboratoryAddress: Data.LaboratoryAddress,
          }})
        return res;
    }
    catch(error){
        console.log("Lab and user registration error",error)
        throw error     
    }
}
export const GetUser_ForDashboard = async(LabID) =>{
  try{
      const response = await apiService.get(`Auth/GetUser_ForDashboard?LabId=${LabID}` )
      return response.data;
  }
 catch(error){
  console.log("getting  user error",error)
  throw error
 }
}
export const GetSingleUser = async(LabID,UserID) =>{
  try{
      const response = await apiService.get(`Auth/GetSingleUser?LabId=${LabID}&userID=${UserID}` )
      return response.data;
  }
 catch(error){
  console.log("getting  user error",error)
  throw error
 }
}

export const EditUser = async(Data) =>{
  try{
      const response = await apiService.post('Auth/EditUser',
      {
        FirstName: Data.FirstName,
        LastName: Data.LastName,
        Email:Data.Email,
        PhoneNumber:Data.PhoneNumber,
        Password:Data.Password,
        user_Role: Data.user_Role,
        LaboratoryID:Data.LaboratoryID
      }
      )
      return response;
  }
 catch(error){
  console.log("Add user error",error)
  throw error
 }
 
}