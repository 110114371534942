import React, { useEffect, useState,useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import {GloabalSearchPatient } from '../Services/PatientServicces';
import { GetDashboard } from '../Services/Dashboard';
import { toast } from 'react-toastify';
import SpinnerModal from '../Spinner/SpinnerModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../UI/Layout.css';
import { blue } from '@mui/material/colors';
import { withTheme } from '@emotion/react';
import image from '../assets/SS.png'
const drawerWidth = '5%';
const bgcolor = '#2c6bd7';
const color = '#ffff'

const AppBar = styled('div')(({ theme }) => ({
  //...theme.mixins.toolbar,
  backgroundColor: '#ffffff',
  boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;',
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dashboardData, setDashboardData] = useState([]);
  const [User_Role, SetUser_Role] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [FirstN, setFirstName] = useState('');
  const [LastN, setLastName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [PatientData,setPatientData] = useState([]);
  const [inputdata, setInputData] = useState('');
  const tblRef = useRef(null);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const HandleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };

  const GetLabDetails = async () => {
    const LabID = sessionStorage.getItem('LabID');
    const response = await GetDashboard(LabID);
    setDashboardData(response);
  };

  useEffect(() => {
    const User_Role = sessionStorage.getItem('User_Role');
    SetUser_Role(User_Role);
    GetLabDetails();
    const FirstName = sessionStorage.getItem('FirstName');
    const LastName = sessionStorage.getItem('LastName');
    setFirstName(FirstName?.charAt(0));
    setLastName(LastName?.charAt(0));
  }, []);

  const isActive = (path) => location.pathname === path;

  //================Search patient =============
const handleChange = (e) => {
  const value = e.target.value;
  setInputData(value);
};

const hnadleSearchPatient = async () => {
  setIsLoading(true);
  try {
        if(inputdata != null || inputdata != ""){
          const LabID = sessionStorage.getItem('LabID');
          const result = await GloabalSearchPatient(LabID, inputdata);
              setPatientData(result);
        }
          
      } catch (error) {
        console.log('error while searching patient', error);
      } finally {
        setIsLoading(false);
      }
};
//===================END==================
////--------------open profile------------

const NavigatetoProfile = (id) =>{
  navigate(`/Home/PatientProfile/${id}`);
  setPatientData("");
}
//------------end---------------
  //-------------------Detect clicks outside of the <ul>----------------------
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tblRef.current && !tblRef.current.contains(event.target)) {
        
        setPatientData(""); 
      }
    };

    document.addEventListener('mousedown', handleClickOutside); // Listen for mouse click

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //---------------------END----------------------
  //-----------Long lab name------------
  const getLimitedText = (text, wordLimit) => {
    if (!text) return ''; 
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };
  //---------------------END-----------
  return (
    <>
      {isLoading && <SpinnerModal isLoading={isLoading} />}
      <Box sx={{ display: 'flex',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            backgroundColor:'#2c6bd7',
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box',backgroundColor:bgcolor,color:color,overflow:'hidden'},
          }}
        >
         <Toolbar>
           <img className='logo'  src={image}></img>
         </Toolbar>
          <List>
            {User_Role === 'Admin' && (
              <ListItem onClick={() => navigate('/Home/Dashboard')} disablePadding>
                <ListItemButton
                 selected={isActive('/Home/Dashboard')}
                 sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',borderLeft: isActive('/Home/Dashboard') ? '4px solid white' : 'none', }}
                 >
                  <ListItemIcon  sx={{ minWidth: '0px' }}>
                    <HomeOutlinedIcon sx={{color:'white'}} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Dashboard"
                      primaryTypographyProps={{ fontSize: 'x-small', fontWeight:'bold',textAlign: 'center' }}
                      sx={{ color: 'white', mt: 1 }}
                    />
                </ListItemButton>
              </ListItem>
            )}
             <ListItem onClick={() => navigate('/Home/PendingTest')} disablePadding>
              <ListItemButton 
               sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',borderLeft: isActive('/Home/PendingTest') ? '4px solid white' : 'none', }}
              selected={isActive('/Home/PendingTest')}>
                <ListItemIcon  sx={{ minWidth: '0px' }}><PendingActionsOutlinedIcon sx={{color:'white'}} /></ListItemIcon>
                <ListItemText
                      primary="PendingTest"
                      primaryTypographyProps={{ fontSize: 'x-small', fontWeight:'bold',textAlign: 'center' }}
                    />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={() => navigate('/Home/PatientRegistration')} disablePadding>
              <ListItemButton 
               selected={isActive('/Home/PatientRegistration')}
               sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',borderLeft: isActive('/Home/PatientRegistration') ? '4px solid white' : 'none', }}
               >
                <ListItemIcon sx={{ minWidth: '0px' }}>
                  <PersonAddAltOutlinedIcon sx={{color:'white'}}  />
                  </ListItemIcon>
                  <ListItemText
                      primary="Registration"
                      primaryTypographyProps={{ fontSize: 'x-small', fontWeight:'bold',textAlign: 'center' }}
                      sx={{ color: 'white', mt: 1 }}
                    />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={() => navigate('/Home/PatientList')} disablePadding>
              <ListItemButton 
                selected={isActive('/Home/PatientList')}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',borderLeft: isActive('/Home/PatientList') ? '4px solid white' : 'none', }}
                >
                <ListItemIcon sx={{ minWidth: '0px' }}>
                  <Diversity1OutlinedIcon sx={{color:'white'}} />
                  </ListItemIcon>
                  <ListItemText
                      primary="Patients"
                      primaryTypographyProps={{ fontSize: 'x-small', fontWeight:'bold',textAlign: 'center' }}
                      sx={{ color: 'white', mt: 1 }}
                    />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={() => navigate('/Home/Reports')} disablePadding>
              <ListItemButton 
               sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',borderLeft: isActive('/Home/Reports') ? '4px solid white' : 'none', }}
              selected={isActive('/Home/Reports')}>
                <ListItemIcon  sx={{ minWidth: '0px' }}><AssessmentOutlinedIcon sx={{color:'white'}} /></ListItemIcon>
                <ListItemText
                      primary="Reports"
                      primaryTypographyProps={{ fontSize: 'x-small', fontWeight:'bold',textAlign: 'center' }}
                    />
              </ListItemButton>
            </ListItem>
           
            <ListItem onClick={() => navigate('/Home/TestEntry')} disablePadding>
              <ListItemButton
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',borderLeft: isActive('/Home/TestEntry') ? '4px solid white' : 'none', }}
                  selected={isActive('/Home/TestEntry')}
                  >
                <ListItemIcon sx={{ minWidth: '0px' }} ><BiotechOutlinedIcon sx={{color:'white'}} /></ListItemIcon>
                <ListItemText
                      primary="Test"
                      primaryTypographyProps={{ fontSize: 'x-small', fontWeight:'bold',textAlign: 'center' }}
                      sx={{ color: 'white', mt: 1 }}
                    />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={() => navigate('/Home/TemplateSetting')} disablePadding>
              <ListItemButton 
               sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',borderLeft: isActive('/Home/TemplateSetting') ? '4px solid white' : 'none', }}
              selected={isActive('/Home/TemplateSetting')}>
                <ListItemIcon  sx={{ minWidth: '0px' }}><NoteAltOutlinedIcon sx={{color:'white'}} /></ListItemIcon>
                <ListItemText
                      primary="Template"
                      primaryTypographyProps={{ fontSize: 'x-small', fontWeight:'bold',textAlign: 'center' }}
                    />
              </ListItemButton>
            </ListItem>
            {User_Role === 'Admin' && (
              <ListItem onClick={() => navigate('/Home/User-Management')} disablePadding>
                <ListItemButton 
                 sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',borderLeft: isActive('/Home/User-Management') ? '4px solid white' : 'none', }}
                selected={isActive('/Home/User-Management')}>
                  <ListItemIcon  sx={{ minWidth: '0px' }}><ManageAccountsOutlinedIcon sx={{color:'white'}} /></ListItemIcon>
                  <ListItemText
                      primary="Accounts"
                      primaryTypographyProps={{ fontSize: 'x-small', fontWeight:'bold',textAlign: 'center' }}
                      sx={{ color: 'white', mt: 1 }}
                    />
                </ListItemButton>
                
              </ListItem>
            )}
           
          </List>
        </Drawer>

        {/* Main Content */}
        <Box className='Horizental-bar' sx={{ flexGrow: 1, position:'fixed',width:'100%' ,zIndex:'999',textAlign:'center'}}>
          <AppBar>
            <div className='lab-name' style={{minWidth:'6rem'}}>
              <h5 className='common-FontWeight common-FontFamily'> 
                  {getLimitedText(dashboardData?.labDetails?.laboratoryName, 2)}
                 
              </h5> 
            </div>
            {/* <div className='parent-inputsearch'> */}
              <div className='InputSearch'>
                <button
                  className="searchButton"
                  onClick={hnadleSearchPatient}
                >
                  <SearchOutlinedIcon />
                </button>
                <input
                  style={{ border: 'none', outline: 'none', fontSize: 'smaller', width: '80%',marginLeft:'15px' }}
                  onKeyDown={(e) => { if (e.key === 'Enter') hnadleSearchPatient(); }}
                  onChange={handleChange}
                  placeholder="Search patient by name/contact number"
                />
              </div> 
              
             
            
            {/* </div> */}
            <Avatar sx={{ bgcolor: '#2c6bd7', cursor: 'pointer' }} onClick={handleMenuOpen}>
              {FirstN + LastN}
            </Avatar>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={HandleLogout}>
                <LockOutlinedIcon /> Log Out
              </MenuItem>
            </Menu>
          </AppBar>
            {
              PatientData.length > 0 && (
                <table ref={tblRef}   className='search-tbl table  table-striped'>
                  <thead>
                    <tr className='common-FontSize-small common-FontFamily common-FontWeight'>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Age</th>
                    </tr>
                  </thead>
                   <tbody>
                   {PatientData.map((item, index) => (
                     <tr className='common-FontSize-small common-FontFamily common-FontWeight'
                     key={index} onClick={() => NavigatetoProfile(item.patientID) }>
                      <td>{item.patientName}</td>
                      <td>{item.contactNumber}</td>
                      <td>{item.age}</td>

                    </tr>
                  ))}
                   </tbody>
                </table>
              
              )
            }
            
       </Box>
      </Box>
    </>
  );
};

export default Layout;
