import React from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import QRCode from 'react-qr-code';  // Import react-qr-code

import '../UI/Template.css';

const Template3 = ({ reportData ,QRurl}) => {
  const currentDateTime = new Date();
  const FormateCurrentDate = moment(currentDateTime).format('D MMM YYYY | HH:mm:ss');
  const Username = sessionStorage.getItem("FirstName") + sessionStorage.getItem("LastName");

  const groupByCategoryAndSubCategory = (data) => {
    return data.reduce((result, item) => {
      const category = item.categorY_CODE;
      const subCategory = item.suB_CATEGORY_CODE;

      if (!result[category]) {
        result[category] = {
          categoryValue: item.categorY_VALUE,
          subCategories: {},
        };
      }

      if (!result[category].subCategories[subCategory]) {
        result[category].subCategories[subCategory] = {
          subCategoryValue: item.suB_CATEGORY_VALUE,
          items: [],
        };
      }

      result[category].subCategories[subCategory].items.push(item);
      return result;
    }, {});
  };

  const groupedData = groupByCategoryAndSubCategory(reportData);

  const formatTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const LabName = reportData.find((x) => x.laboratoryName != '' && x.laboratoryName != null)?.laboratoryName;
  const qrCodeValue = QRurl;

  return (
    <>
        {/* Header Section (Patient Info, Lab Info, QR Code) */}
        <div className="print-header" style={{ padding: '1rem', fontSize: '10px', fontFamily: 'sans-serif' }}>
          <h3 style={{ fontStyle: 'bold', fontFamily: 'sans-serif', fontWeight: '900', fontSize: '30px' }}>
            {LabName}
          </h3>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <div style={{ textAlign: 'start' }}>
                <span style={{ fontSize: '13px', fontFamily: 'sans-serif' }}>
                  {reportData?.[0]?.laboratoryAddress}, {reportData?.[0]?.laboratoryEmail}
                </span><br />
                <span style={{ fontSize: '12px', fontFamily: 'sans-serif' }}>
                  Contact: {reportData?.[0]?.laboratoryContactNumber}
                </span><br />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <div style={{ textAlign: 'end' }}>
                  <span className='Template3_Patient_Info'>Mr No:&nbsp;{reportData?.[0]?.patientID}-{sessionStorage.getItem('LabID')}</span>
                </div>
                <div style={{ textAlign: 'end' }}>
                  <span className='Template3_Patient_Info'>Patient Name:&nbsp;{reportData?.[0]?.patientName}</span>
                </div>
                <div style={{ textAlign: 'end' }}>
                  <span className='Template3_Patient_Info'>Age|Gender:&nbsp;{reportData?.[0]?.age} Years|{reportData?.[0]?.sex}</span>
                </div>
                <div style={{ textAlign: 'end' }}>
                  <span className='Template3_Patient_Info'>Phone Number:&nbsp;{reportData?.[0]?.contactNumber}</span>
                </div>
                <div style={{ textAlign: 'end' }}>
                  <span className='Template3_Patient_Info'>Referred by:&nbsp;{reportData?.[0]?.referredBy}</span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        {/* Content Section */}
        <div className="print-content"  style={{padding:'0px 10px'}}>
          <div>
            <span>Reported At: {moment(reportData?.[0]?.createdAt).format('D MMM YYYY | HH:mm:ss')}</span>
          </div>

          {/* Table Section */}
          {Object.keys(groupedData).map((category, index) => (
            <div key={index}>
              <h5 className='common-FontWeight common-FontFamily' style={{ textAlign: 'center' }}>
                {groupedData[category].categoryValue}
              </h5>
              {Object.keys(groupedData[category].subCategories).map((subCategory, subIndex) => (
                <div key={subIndex}>
                  <h6 className='common-FontWeight common-FontFamily' style={{ textAlign: 'left' }}>
                    {groupedData[category].subCategories[subCategory].subCategoryValue}
                  </h6>
                  <table style={{ width: '100%' }}>
                    <thead style={{ backgroundColor: 'lightgray' }}>
                      <tr>
                        <th>Test Description</th>
                        <th>Result</th>
                        <th>Unit</th>
                        <th>Normal Range</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedData[category].subCategories[subCategory].items.map((data, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{data.testName}</td>
                            <td style={{ fontWeight: 'bold' }}>{data.testResult}</td>
                            <td>{formatTextWithLineBreaks(data.unit)}</td>
                            <td>{formatTextWithLineBreaks(data.normalRange)}</td>
                          </tr>
                          {data.comment && data.comment.trim() !== '' && (
                            <tr>
                              <td colSpan={4} style={{ paddingLeft: '20px', fontStyle: 'italic' }}>
                                {data.comment}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                  <hr />
                </div>
              ))}
            </div>
          ))}

          <div>
            <span>Printed at: {FormateCurrentDate}</span> &nbsp; <span>Printed By: {Username}</span>
          </div>

          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <QRCode value={qrCodeValue} size={150} />
            <p>Scan to Download PDF</p>
          </div>
        </div>
    </>
  );
};

export default Template3;
